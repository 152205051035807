import { Button } from '@mdb/flora';
import theme from '@mdb/flora/theme';

export const LoveDeveloper = () => {
    return (
        <div
            sx={{
                backgroundColor: theme.colors.black80,
                padding: 'inc50',
                color: theme.colors.black00,
                marginBottom: '1px',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <div>
                <div>
                    <h3 sx={{ marginBottom: 0 }}>LOVE YOUR DEVELOPERS</h3>
                </div>
                <div
                    sx={{ display: 'flex', flexDirection: 'row', gap: 'inc40' }}
                >
                    <h4>
                        Developers have always shaped the history of technology.
                        And no matter the future, developers will build it.
                    </h4>
                    <Button
                        href="https://www.mongodb.com/company/love-your-developers "
                        target="_blank"
                        variant="primary"
                        size="large"
                    >
                        Learn Why
                    </Button>
                </div>
            </div>
        </div>
    );
};

export const isAfterMarch82024 = () => {
    const currentDate = new Date();
    const lowerBound =
        process.env.APP_ENV === 'production'
            ? new Date(2024, 2, 8) // March 8th, 2024
            : new Date(2024, 2, 6); // March 6th, 2024
    return currentDate >= lowerBound;
};

export default LoveDeveloper;
