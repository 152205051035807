import { useEffect, RefObject } from 'react';

const useAddClassToInput = (
    ref: RefObject<HTMLDivElement>,
    dependency: any
) => {
    useEffect(() => {
        if (ref && ref.current) {
            const inputElement =
                ref.current.querySelector('input[type="text"]');
            if (inputElement) {
                inputElement.classList.add('sl-search-input');
            }
        }
    }, [ref, dependency]);
};

export default useAddClassToInput;
